import(/* webpackMode: "eager", webpackExports: ["AdminProviders"] */ "/app/apps/web/src/app/(Admin)/AdminProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdminPortalProviders"] */ "/app/apps/web/src/app/(Admin)/portal/(AdminPortal)/AdminPortalProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/reactflow/dist/style.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/spotlight/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/carousel/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/dates/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/charts/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/pages/_app.css");
